import { useMutation } from '@tanstack/react-query';
import { MutationConfig } from '@/core/lib/query-client';
import { changeUsername } from '../api/change-username.api';

type UseChangeUsernameOptions = {
  mutationConfig?: MutationConfig<typeof changeUsername>;
};

const useChangeUsername = ({ mutationConfig }: UseChangeUsernameOptions = {}) => {
  const { onSuccess, ...restConfig } = mutationConfig || {};

  return useMutation({
    onSuccess,
    ...restConfig,
    mutationFn: changeUsername,
  });
};

export default useChangeUsername;
