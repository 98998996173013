import { Dialog, DialogBackdrop, DialogPanel } from '@headlessui/react';
import usePlayground from '../../hooks/usePlayground.hook';
import { CloseIcon } from '@/assets/icons/close.icon';
import { TabGroup, TabPanel, TabPanels } from '@headlessui/react';
import { EnterKBD } from '@/core/components/form/fields/EnterKBD';
import { useRef, useState } from 'react';
import { removeHttpProtocol } from '@/core/utils/remove-http-protocol';
import { DefaultDnsRecords } from '@/core/constants/default-dns-records.constants';
import toast from 'react-hot-toast';
import TwitterText from 'twitter-text';
import useAddDomain from '@/modules/link-in-bio/hooks/domain/useAddDomain.hook';
import { PlaygroundActionType } from '../../types/playground-action.type';
import _ from 'lodash';
import { cn } from '@/core/utils/cn';
import { LinkInBioDto } from '@link-in-bio/domain';
import { ENUM_DOMAIN_STATUS } from '../../enums/domain-status.enum';
import useDnsLookup from '@/modules/link-in-bio/hooks/domain/useDnsLookup.hook';

type SettingsDialogProps = {
  isOpen: boolean;
  toggleDialog: () => void;
};

const SettingsDialog = ({ isOpen, toggleDialog }: SettingsDialogProps) => {
  const { state, dispatch, changeUsername } = usePlayground();
  const [isDnsLookupLoading, setIsDnsLookupLoading] = useState<boolean>(false);
  const [copyButtonText, setCopyButtonText] = useState<string>('copy');

  const freeDomainRef = useRef<HTMLInputElement | null>(null);
  const customDomainRef = useRef<HTMLInputElement | null>(null);

  const addDomain = useAddDomain({
    mutationConfig: {
      onSuccess: ({ data }: { data: LinkInBioDto }) => {
        dispatch({
          type: PlaygroundActionType.SET_PAYLOAD,
          payload: {
            domain: data.domain,
            domain_status: data.domain_status,
          },
        });
      },
    },
  });

  const dnsLookup = useDnsLookup({
    mutationConfig: {
      onSuccess: ({ data }: { data: { status: ENUM_DOMAIN_STATUS } }) => {
        dispatch({
          type: PlaygroundActionType.SET_PAYLOAD,
          payload: {
            domain_status: data.status,
          },
        });
      },
    },
  });

  const changeFreeDomainHandler = () => {
    const username = freeDomainRef.current?.value;

    if (!username) {
      toast.error('Please enter username!');
      return;
    }

    changeUsername.mutate({
      linkInBioId: state.payload._id!,
      username,
    });
  };

  const changeCustomDomainHandler = () => {
    const customDomain = customDomainRef.current?.value;

    if (!customDomain) {
      toast.error('Please enter a domain!');
      return;
    }

    if (customDomain && !TwitterText.isValidUrl(customDomain, true, false)) {
      toast.error('Please enter a valid domain!');
      return;
    }

    addDomain.mutate({
      linkInBioId: state.payload._id!,
      domain: customDomain,
    });
  };

  const dnsLookupHandler = async () => {
    setIsDnsLookupLoading(true);

    try {
      await dnsLookup.mutateAsync({
        domain: state.payload.domain!,
      });
    } finally {
      setIsDnsLookupLoading(false);
    }
  };

  const getStatusColor = () => {
    switch (state.payload.domain_status) {
      case ENUM_DOMAIN_STATUS.LIVE:
        return 'bg-success';
      case ENUM_DOMAIN_STATUS.PENDING:
        return 'bg-pending';
      case ENUM_DOMAIN_STATUS.FAILED:
        return 'bg-failed';
      default:
        return 'bg-pending';
    }
  };

  const handleCopyClick = (value: string) => {
    navigator.clipboard
      .writeText(value)
      .then(() => {
        setCopyButtonText('copied');

        setTimeout(() => {
          setCopyButtonText('copy');
        }, 1000);
      })
      .catch((err) => {
        console.error('Failed to copy text: ', err);
      });
  };

  return (
    <Dialog open={isOpen} onClose={toggleDialog} className="relative z-50">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-[rgba(0,0,0,0.40)] backdrop-[blur(2px)] duration-200 ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0"
      />

      <div className="fixed inset-0 flex flex-col w-screen items-center justify-center">
        <DialogPanel
          transition
          className="relative w-[90%] md:w-[600px] space-y-4 bg-transparent duration-200 ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0 overflow-auto max-h-screen scrollbar-hide py-6"
        >
          <div className="p-3 md:p-6 pb-10 md:pb-20 rounded-2xl bg-dark border border-1 border-secondary">
            <div onClick={toggleDialog}>
              <CloseIcon top="top-10" />
            </div>

            <TabGroup vertical className="mt-10 md:mt-0 block md:flex w-full gap-x-10">
              {/* <TabList className="w-full md:w-[30%] mb-5 md:mb-0 p-1 md:p-0 bg-main md:bg-transparent rounded-xl md:rounded-none">
                <Tab className="w-full flex items-center justify-center md:justify-start bg-surface-dark text-lighter rounded-lg p-2 gap-x-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12.75 3.03v.568c0 .334.148.65.405.864l1.068.89c.442.369.535 1.01.216 1.49l-.51.766a2.25 2.25 0 0 1-1.161.886l-.143.048a1.107 1.107 0 0 0-.57 1.664c.369.555.169 1.307-.427 1.605L9 13.125l.423 1.059a.956.956 0 0 1-1.652.928l-.679-.906a1.125 1.125 0 0 0-1.906.172L4.5 15.75l-.612.153M12.75 3.031a9 9 0 0 0-8.862 12.872M12.75 3.031a9 9 0 0 1 6.69 14.036m0 0-.177-.529A2.25 2.25 0 0 0 17.128 15H16.5l-.324-.324a1.453 1.453 0 0 0-2.328.377l-.036.073a1.586 1.586 0 0 1-.982.816l-.99.282c-.55.157-.894.702-.8 1.267l.073.438c.08.474.49.821.97.821.846 0 1.598.542 1.865 1.345l.215.643m5.276-3.67a9.012 9.012 0 0 1-5.276 3.67m0 0a9 9 0 0 1-10.275-4.835M15.75 9c0 .896-.393 1.7-1.016 2.25"
                    />
                  </svg>
                  Domain
                </Tab>
              </TabList> */}
              <TabPanels className="w-full">
                <TabPanel>
                  <div className="text-xl text-light font-semibold mb-6 hidden md:block">Domains</div>

                  <div className="flex items-start justify-between">
                    <div>
                      <div className="text-lighter font-semibold">Free Domain</div>
                      <div className="text-light text-sm mt-1 mb-4">Type to change your username</div>
                    </div>
                    <div className="flex items-center gap-x-3">
                      <span className="relative flex size-3">
                        <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-success opacity-75"></span>
                        <span className="relative inline-flex size-3 rounded-full bg-success"></span>
                      </span>
                      <span className="text-lighter">Live</span>
                    </div>
                  </div>
                  <div className="relative flex items-center w-full h-auto rounded-lg border border-solid border-secondary bg-main">
                    <span className="flex select-none items-center pl-2 text-light">
                      {removeHttpProtocol(process.env.HOME_URL!)}/
                    </span>
                    <input
                      ref={freeDomainRef}
                      defaultValue={state.payload.username}
                      type="text"
                      className="block w-full h-10 border-0 bg-transparent py-[14px] pr-2 text-white focus:outline-none"
                    />
                    <EnterKBD
                      color="primary"
                      text="Save"
                      onClick={changeFreeDomainHandler}
                      disabled={freeDomainRef.current?.value === state.payload.username}
                    />
                  </div>
                  {state.payload.domain_status === ENUM_DOMAIN_STATUS.LIVE ? (
                    <span className="text-lighter text-xs">
                      Your Linkie will automatically redirect to your custom domain
                    </span>
                  ) : null}

                  <div className="w-full h-[1px] bg-secondary my-6" />

                  <div className="flex items-start justify-between">
                    <div>
                      <div className="text-lighter font-semibold">Custom Domain</div>
                      <div className="text-light text-sm mt-1 mb-4">Type to connect a domain you own</div>
                    </div>

                    {state.payload.domain ? (
                      <div className="flex items-center gap-x-3">
                        <span className="relative flex size-3">
                          <span
                            className={cn(
                              'absolute inline-flex h-full w-full animate-ping rounded-full opacity-75',
                              getStatusColor(),
                            )}
                          ></span>
                          <span className={cn('relative inline-flex size-3 rounded-full', getStatusColor())}></span>
                        </span>
                        <span className="text-lighter">{_.startCase(_.toLower(state.payload.domain_status))}</span>
                      </div>
                    ) : null}
                  </div>

                  <div className="relative flex items-center w-full h-auto">
                    <input
                      ref={customDomainRef}
                      defaultValue={state.payload.domain}
                      type="text"
                      placeholder="www.example.com"
                      className="block w-full h-10 rounded-lg border border-solid border-secondary py-[14px] px-2 text-white bg-main focus:outline-none focus:border-input-focus"
                    />
                    <EnterKBD
                      color="primary"
                      text="Save"
                      onClick={changeCustomDomainHandler}
                      disabled={
                        !customDomainRef.current?.value || customDomainRef.current.value === state.payload.domain
                      }
                    />
                  </div>

                  {state.payload.domain && (
                    <>
                      <div className="my-4 flex items-center justify-between">
                        <span className="text-light text-sm">DNS records must be set as the following</span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className={cn('size-5 text-lighter cursor-pointer', isDnsLookupLoading && 'animate-spin')}
                          onClick={dnsLookupHandler}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
                          />
                        </svg>
                      </div>

                      <div className="flow-root mb-2">
                        <div className="-my-2 overflow-x-auto">
                          <div className="inline-block w-full py-2 align-middle">
                            <div className="overflow-hidden rounded-lg">
                              <table className="w-full divide-y divide-secondary">
                                <thead className="bg-secondary">
                                  <tr>
                                    <th
                                      scope="col"
                                      className="w-1/3 py-2 pl-4 pr-3 text-left text-sm font-normal text-light"
                                    >
                                      Type
                                    </th>
                                    <th
                                      scope="col"
                                      className="w-1/3 px-3 py-2 text-left text-sm font-normal text-light"
                                    >
                                      Name
                                    </th>
                                    <th
                                      scope="col"
                                      className="w-1/3 px-3 py-2 text-left text-sm font-normal text-light"
                                    >
                                      Value
                                    </th>
                                  </tr>
                                </thead>
                                <tbody className="divide-y divide-secondary bg-main">
                                  {DefaultDnsRecords.map((dnsRecord, index: number) => (
                                    <tr key={index}>
                                      <td className="w-1/3 whitespace-nowrap py-4 pl-4 pr-3 text-sm text-lighter">
                                        {dnsRecord.type}
                                      </td>
                                      <td className="w-1/3 whitespace-nowrap px-3 py-4 text-sm text-lighter">
                                        {dnsRecord.name}
                                      </td>
                                      <td className="w-1/3 relative whitespace-nowrap px-3 py-4 text-sm text-lighter cursor-pointer group">
                                        <span className="group-hover:blur-[1px] group-hover:opacity-50 transition duration-200">
                                          {dnsRecord.value}
                                        </span>
                                        <span
                                          className="absolute inset-0 flex items-center left-11 opacity-0 group-hover:opacity-100 text-sm text-lighter transition duration-200"
                                          onClick={() => {
                                            handleCopyClick(dnsRecord.value);
                                          }}
                                        >
                                          {copyButtonText}
                                        </span>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>

                      <span className="text-light text-sm">📢 DNS changes may take up to 48 hours to propagate</span>
                    </>
                  )}
                </TabPanel>
              </TabPanels>
            </TabGroup>
          </div>
        </DialogPanel>
      </div>
    </Dialog>
  );
};

export default SettingsDialog;
