import Button from '@/core/design-system/Button';

const LinkieCustomDomainError = () => {
  const returnToHomepage = () => {
    window.location.href = `${process.env.CLIENT_URL!}/auth/login`;
  };

  return (
    <>
      <h1 className="text-lighter text-center text-3xl font-bold">
        This custom domain is not set up correctly in Linkie.
      </h1>
      <p className="text-lighter text-center text-lg font-bold mt-4">
        Please log into your Linkie account and double-check the settings.
      </p>
      <Button type="button" className="w-40 mx-auto mt-10" onClick={returnToHomepage}>
        Login
      </Button>
    </>
  );
};

export default LinkieCustomDomainError;
