import { useMutation } from '@tanstack/react-query';
import { MutationConfig } from '@/core/lib/query-client';
import { dnsLookup } from '../../api/custom-domain/dns-lookup.api';

type UseDnsLookupOptions = {
  mutationConfig?: MutationConfig<typeof dnsLookup>;
};

const useDnsLookup = ({ mutationConfig }: UseDnsLookupOptions = {}) => {
  const { onSuccess, ...restConfig } = mutationConfig || {};

  return useMutation({
    onSuccess,
    ...restConfig,
    mutationFn: dnsLookup,
  });
};

export default useDnsLookup;
