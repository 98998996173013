import { useState } from 'react';
import SettingsDialog from './SettingsDialog';
import SettingsButton from './SettingsButton';

type SettingsProps = {
  closeMenu: () => void;
};

const Settings = ({ closeMenu }: SettingsProps) => {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const toggleDialog = () => {
    closeMenu();
    setIsDialogOpen(!isDialogOpen);
  };

  return (
    <>
      <SettingsButton toggleDialog={toggleDialog} />
      <SettingsDialog isOpen={isDialogOpen} toggleDialog={toggleDialog} />
    </>
  );
};

export default Settings;
