import { CameraIcon } from '@/assets/icons';
import { PointerEventVariants } from '@/core/constants/mode-variants/event.variants';
import useAvatarUpload from '@/modules/playground/hooks/useAvatarUpload.hook';
import ModeRenderization from '../../ModeRenderization';

const Avatar = () => {
  const { state, fileInputRef, openExplorer, uploadAvatarHandler, getAvatarSrc } = useAvatarUpload();

  return (
    <>
      <ModeRenderization
        variants={PointerEventVariants}
        className="relative group cursor-pointer"
        onClick={openExplorer}
      >
        {state.payload._id ? (
          <img src={getAvatarSrc()} alt="Avatar" className="w-24 h-24 rounded-full object-cover" />
        ) : (
          <div className="w-24 h-24 bg-dark animate-pulse rounded-full"></div>
        )}
        <div className="absolute inset-0 bg-black opacity-0 group-hover:opacity-50 rounded-full transition-all duration-200"></div>
        <div className="absolute inset-0 flex items-center justify-center text-white opacity-0 group-hover:opacity-100 transition-all duration-200">
          <CameraIcon />
        </div>
      </ModeRenderization>

      <input
        ref={fileInputRef}
        type="file"
        accept=".jpg,.jpeg,.png,.webp"
        className="hidden"
        id="avatar"
        name="avatar"
        onChange={uploadAvatarHandler}
      />
    </>
  );
};

export default Avatar;
