import { removeHttpProtocol } from '../utils/remove-http-protocol';

export const DefaultDnsRecords = [
  {
    type: 'CNAME',
    name: '@',
    value: removeHttpProtocol(process.env.CLIENT_URL!),
  },
  {
    type: 'CNAME',
    name: 'www',
    value: removeHttpProtocol(process.env.CLIENT_URL!),
  },
];
