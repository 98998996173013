import { ChevronDownIcon } from '@/assets/icons/chevron-down.icon';
import { ExternalLinkIcon } from '@/assets/icons/external-link.icon';
import Button from '@/core/design-system/Button';
import { removeHttpProtocol } from '@/core/utils/remove-http-protocol';
import NoltWidget from '@/core/widgets/Nolt.widget';
import useAuth from '@/modules/auth/hooks/useAuth.hook';
import Settings from '@/modules/playground/components/settings';
import { PlaygroundMode } from '@/modules/playground/enums/mode.enum';
import usePlayground from '@/modules/playground/hooks/usePlayground.hook';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';

const Header = () => {
  const { state, handleSubmit, changeMode, openPreview } = usePlayground();
  const { state: authState, logout } = useAuth();

  return (
    <div className="flex justify-between flex-col md:flex-row py-8 px-24 z-50">
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <MenuButton className="inline-flex w-full justify-center gap-x-2 text-sm font-semibold text-gray-900">
            <p className="text-light font-bold cursor-pointer">
              {removeHttpProtocol(process.env.HOME_URL ?? '')}/
              <span className="text-white">{state.payload.username}</span>
            </p>
            <ChevronDownIcon className="-mr-1 h-5 w-5 text-[#525760]" />
          </MenuButton>
        </div>

        <MenuItems
          unmount={false}
          transition
          className="px-2 absolute mt-2 w-56 origin-top-right rounded-lg bg-main ring-1 ring-secondary transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
        >
          <div className="py-1">
            <MenuItem>
              <a
                href="#"
                className="flex items-center justify-between px-2 py-4 text-sm text-lighter font-bold"
                onClick={openPreview}
              >
                <span>/{state.payload.username}</span>
                <ExternalLinkIcon />
              </a>
            </MenuItem>
            <MenuItem>
              <div className="border-t border-secondary border-t-1">
                <NoltWidget>
                  <button
                    type="button"
                    className="nolt-button text-left flex items-center justify-between gap-x-2 mt-3 mb-2 py-2 px-3 rounded-lg w-full bg-transparent border border-secondary border-1 text-light font-bold text-sm hover:bg-dark transition-all duration-200 ease-in-out"
                  >
                    Leave Feedback
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="size-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M11.48 3.499a.562.562 0 0 1 1.04 0l2.125 5.111a.563.563 0 0 0 .475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 0 0-.182.557l1.285 5.385a.562.562 0 0 1-.84.61l-4.725-2.885a.562.562 0 0 0-.586 0L6.982 20.54a.562.562 0 0 1-.84-.61l1.285-5.386a.562.562 0 0 0-.182-.557l-4.204-3.602a.562.562 0 0 1 .321-.988l5.518-.442a.563.563 0 0 0 .475-.345L11.48 3.5Z"
                      />
                    </svg>
                  </button>
                </NoltWidget>
              </div>
            </MenuItem>
            {authState.isAuthenticated ? (
              <>
                <MenuItem>{({ close }) => <Settings closeMenu={close} />}</MenuItem>
                <MenuItem>
                  <button
                    type="button"
                    onClick={logout}
                    className="text-left flex items-center justify-between gap-x-2 mt-1 mb-2 py-2 px-3 rounded-lg w-full bg-dark border border-secondary border-1 text-light font-bold text-sm hover:bg-secondary transition-all duration-200 ease-in-out"
                  >
                    Log Out
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="size-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15.75 9V5.25A2.25 2.25 0 0 0 13.5 3h-6a2.25 2.25 0 0 0-2.25 2.25v13.5A2.25 2.25 0 0 0 7.5 21h6a2.25 2.25 0 0 0 2.25-2.25V15m3 0 3-3m0 0-3-3m3 3H9"
                      />
                    </svg>
                  </button>
                </MenuItem>
              </>
            ) : null}
          </div>
        </MenuItems>
      </Menu>

      <div className="flex md:grid grid-cols-3 gap-4 items-center mt-4 md:mt-0">
        <Button type="button" variant="outlined" color="secondary" className="md:w-20" onClick={changeMode}>
          {PlaygroundMode.EDITOR === state.mode ? 'Preview' : 'Edit'}
        </Button>
        <Button type="button" className="md:w-20" onClick={handleSubmit} disabled={state.actions.length === 0}>
          Save
        </Button>
      </div>
    </div>
  );
};

export default Header;
