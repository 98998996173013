import { LinkDto } from '@link-in-bio/domain';
import { CameraIcon } from '@/assets/icons';
import useLinkImageUpload from '@/modules/playground/hooks/useLinkImageUpload.hook';
import { ThumbnailDefaultIcon } from '@/assets/icons/thumbnail-default.icon';
import { useState } from 'react';

type LinkPreviewThumbnailProps = {
  link: LinkDto;
};

const LinkPreviewThumbnail = ({ link }: LinkPreviewThumbnailProps) => {
  const {
    fileInputRef: mainFileInputRef,
    openExplorer: mainOpenExplorer,
    uploadImageHandler: mainUploadImageHandler,
  } = useLinkImageUpload();

  const [hasImageError, setHasImageError] = useState<boolean>(false);

  const getThumbnailSrc = () => {
    if (link.thumbnail_url) return link.thumbnail_url;
    return undefined;
  };

  const onImageError = () => {
    setHasImageError(true);
  };

  const renderThumbnail = () => {
    if (hasImageError) {
      return <ThumbnailDefaultIcon />;
    }

    if (getThumbnailSrc()) {
      return (
        <img
          src={getThumbnailSrc()}
          alt="Link Image"
          className="rounded-lg w-[80%] sm:w-full h-full max-h-[150px] object-cover mx-auto"
          onError={onImageError}
        />
      );
    }

    return <ThumbnailDefaultIcon />;
  };

  return (
    <>
      <div className="relative group/thumbnail cursor-pointer h-full w-full" onClick={mainOpenExplorer}>
        {renderThumbnail()}
        <div className="absolute inset-0 bg-black opacity-0 group-hover/thumbnail:opacity-50 rounded-lg transition-all duration-200"></div>
        <div className="absolute inset-0 flex items-center justify-center text-white opacity-0 group-hover/thumbnail:opacity-100 transition-all duration-200">
          <CameraIcon />
        </div>
      </div>

      <input
        ref={mainFileInputRef}
        type="file"
        accept=".jpg,.jpeg,.png,.webp"
        className="hidden"
        id="thumbnail"
        name="thumbnail"
        onChange={(e) => mainUploadImageHandler(e, link._id || link.client_id || '')}
      />
    </>
  );
};

export default LinkPreviewThumbnail;
