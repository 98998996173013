import { useMutation } from '@tanstack/react-query';
import { MutationConfig } from '@/core/lib/query-client';
import { addDomain } from '../../api/custom-domain/add-domain.api';

type UseAddDomainOptions = {
  mutationConfig?: MutationConfig<typeof addDomain>;
};

const useAddDomain = ({ mutationConfig }: UseAddDomainOptions = {}) => {
  const { onSuccess, ...restConfig } = mutationConfig || {};

  return useMutation({
    onSuccess,
    ...restConfig,
    mutationFn: addDomain,
  });
};

export default useAddDomain;
