import { cn } from '@/core/utils/cn';

type CloseIconProps = {
  top?: string;
  right?: string;
  toggleDialog?: () => void;
};

export const CloseIcon = ({ top = 'top-4', right = 'right-4', toggleDialog = () => {} }: CloseIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill="#f3f4f6"
    className={cn('size-5 absolute cursor-pointer', top, right)}
    onClick={toggleDialog}
  >
    <path d="M6.28 5.22a.75.75 0 0 0-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 1 0 1.06 1.06L10 11.06l3.72 3.72a.75.75 0 1 0 1.06-1.06L11.06 10l3.72-3.72a.75.75 0 0 0-1.06-1.06L10 8.94 6.28 5.22Z" />
  </svg>
);
