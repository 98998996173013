import Button from '@/core/design-system/Button';

const LinkieNotFound = () => {
  const returnToHomepage = () => {
    window.location.href = process.env.HOME_URL!;
  };

  return (
    <>
      <h1 className="text-lighter text-center text-4xl font-bold">This Linkie does not exist!</h1>
      <Button type="button" className="w-fit mx-auto my-5" onClick={returnToHomepage}>
        Return to the homepage
      </Button>
    </>
  );
};

export default LinkieNotFound;
