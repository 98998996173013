import { LinkDto } from '@link-in-bio/domain';
import { PlaygroundActionType } from '@/modules/playground/types/playground-action.type';
import { PlaygroundMode } from '@/modules/playground/enums/mode.enum';
import usePlayground from '@/modules/playground/hooks/usePlayground.hook';
import AddLink from './AddLink';
import LinkPreview from './link-preview';
import LinkPreviewProvider from '@/modules/playground/context/link-preview.context';
import { cn } from '@/core/utils/cn';
import { ENUM_LINK_LAYOUT } from '@/modules/playground/enums/link.enum';
import {
  DndContext,
  closestCenter,
  MouseSensor,
  TouchSensor,
  KeyboardSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from '@dnd-kit/core';
import { arrayMove, SortableContext, rectSortingStrategy, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { useCallback } from 'react';

const Links = () => {
  const { state, dispatch } = usePlayground();
  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 200,
        tolerance: 6,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;

      if (active.id !== over?.id) {
        const oldIndex = state.payload.links?.findIndex(
          (link: LinkDto | any) => link._id === active.id || link.client_id === active.id,
        );
        const newIndex = state.payload.links?.findIndex(
          (link: LinkDto | any) => link._id === over!.id || link.client_id === over!.id,
        );

        if (oldIndex !== undefined && newIndex !== undefined) {
          const newLinks = arrayMove(state.payload.links || [], oldIndex, newIndex);

          dispatch({
            type: PlaygroundActionType.REORDER_LINKS,
            payload: newLinks,
          });
        }
      }
    },
    [state.payload.links],
  );

  return (
    <div className="w-full text-center">
      <AddLink />

      <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
        <SortableContext
          items={(state.payload.links || []).map((link: LinkDto | any) => ({
            ...link,
            id: link._id || link.client_id,
          }))}
          strategy={rectSortingStrategy}
          disabled={state.mode !== PlaygroundMode.EDITOR}
        >
          <div
            className={cn(
              'gap-y-3',
              state.mode === PlaygroundMode.EDITOR ? 'grid grid-cols-6' : 'w-full flex flex-wrap justify-center',
            )}
          >
            {state.payload.links
              ?.sort((a: LinkDto | any, b: LinkDto | any) => b.position - a.position)
              .map((link: LinkDto | any) => {
                const layoutType = link.layout;
                const key = `${link._id || link.client_id}-${layoutType}`;

                let gridClass = 'col-span-6 flex-grow flex-shrink basis-full';

                switch (layoutType) {
                  case ENUM_LINK_LAYOUT.ICON:
                    gridClass = 'col-span-1';
                    break;
                  case ENUM_LINK_LAYOUT.LINE:
                    gridClass = 'col-span-6 flex-grow flex-shrink basis-full';
                    break;
                  case ENUM_LINK_LAYOUT.SMALL:
                    gridClass = 'col-span-3 basis-1/2';
                    break;
                  default:
                    gridClass = 'col-span-6 flex-grow flex-shrink basis-full';
                    break;
                }

                return (
                  <div key={key} className={cn('px-1', gridClass)}>
                    <LinkPreviewProvider link={link}>
                      <LinkPreview link={link} />
                    </LinkPreviewProvider>
                  </div>
                );
              })}
          </div>
        </SortableContext>
      </DndContext>
    </div>
  );
};

export default Links;
