import { PlaygroundActionType } from '../types/playground-action.type';
import DefaultAvatarAsset from '@/assets/images/default-avatar.png';
import usePlayground from './usePlayground.hook';
import useImageUpload from '@/core/hooks/useImageUpload.hook';

const useAvatarUpload = () => {
  const { state, dispatch } = usePlayground();
  const { fileInputRef, openExplorer, uploadImage } = useImageUpload();

  const uploadAvatarHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    uploadImage(file, (base64data: string) => {
      dispatch({
        type: PlaygroundActionType.SET_PAYLOAD,
        payload: {
          profile_image_url: base64data,
        },
      });
    });
  };

  const getAvatarSrc = () => {
    if (state.payload.profile_image_url) return state.payload.profile_image_url;
    return DefaultAvatarAsset;
  };

  return {
    state,
    fileInputRef,
    openExplorer,
    uploadAvatarHandler,
    getAvatarSrc,
  };
};

export default useAvatarUpload;
